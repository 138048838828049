/* Setting List, Vue Component */
<template>
  <v-card id="setting-list">
    <v-card-title>Settings</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'setting-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Setting</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field>



        <!-- <v-select
          v-model="filter.Category"
          :items="filterItems.Category"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Filter Category"
          class="mr-4"
        ></v-select> -->


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      group-by="Category"
      class="text-no-wrap"
    >

    <template #[`item.Created`]="{item}">
      {{ Display.DateTime(item.Created) }}
    </template>

    <template #[`item.Value`]="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="text-overflow-ellipses" style="width: 180px;">{{ item.Value }}</div>
        </template>
        <span>{{ item.Value }}</span>
      </v-tooltip>
    </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <ActionsDropdown :item="item" :actionOptions="actionOptions" />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import ActionsDropdown from '@/views/helpers/ActionsDropdown'


export default {
  components: {
    ActionsDropdown,

  },
  setup() {

  // define cols
  const tableColumns = [
    { text: 'Category', value: 'Category' },
		{ text: 'Name', value: 'Name' },
		{ text: 'Value', value: 'Value' },
		{ text: 'Created', value: 'Created' },
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  // threee params for fetching
  const search = ref({});
  const filter = ref({});
  const options = ref({
    sortBy: ['category','name'],
    sortDesc: [false,false],
    itemsPerPage: 20
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.Settings || []);
  const totalItems = computed(() => store.state.app.SettingsTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
  const filterItems = ref({
    Category: []
  });

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchSettings', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });



    const actionOptions = [
      { title: 'View', icon: mdiEyeOutline, target: 'setting-view' },
      { title: 'Edit', icon: mdiPencilOutline, target: 'setting-edit' }
    ]

    return {
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      actionOptions,
      footerProps,

      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
