var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"setting-list"}},[_c('v-card-title',[_vm._v("Settings")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","to":{ name: 'setting-create' }}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Create Setting")])],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search Fields"},model:{value:(_vm.search.all),callback:function ($$v) {_vm.$set(_vm.search, "all", $$v)},expression:"search.all"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"group-by":"Category"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.DateTime(item.Created))+" ")]}},{key:"item.Value",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-overflow-ellipses",staticStyle:{"width":"180px"}},'div',attrs,false),on),[_vm._v(_vm._s(item.Value))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Value))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('ActionsDropdown',{attrs:{"item":item,"actionOptions":_vm.actionOptions}})],1)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }